import React from "react";
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types";
import { Typography } from "@ryerson/frontend.typography";
import { Button } from "@ryerson/frontend.button";
import { Link } from "@ryerson/frontend.navigation";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useTheme } from "@ryerson/frontend.theme";
import { Video } from "@components/Video";
import { BlogNodeBodyReferenceType } from "@graphQL/blogMetaContent";

const VideoContainer = styled.div`
	width: 100%;
	max-width: 560px;
	display: block;
	height: 360px;
	margin: 0 auto;
`;

export const renderRichText = (rawData: any, references?: BlogNodeBodyReferenceType[]) => {
	const { theme } = useTheme();
	const Bold = ({ children }: any) => (
		<Typography weight="bolder" color="inherit">
			{children}
		</Typography>
	);
	const Italics = ({ children }: any) => (
		<Typography italic color="inherit">
			{children}
		</Typography>
	);
	const Underline = ({ children }: any) => (
		<Typography underline color="inherit">
			{children}
		</Typography>
	);
	const options = {
		renderMark: {
			[MARKS.BOLD]: (text: any) => <Bold>{text}</Bold>,
			[MARKS.ITALIC]: (text: any) => <Italics>{text}</Italics>,
			[MARKS.UNDERLINE]: (text: any) => <Underline>{text}</Underline>,
		},
		renderNode: {
			[INLINES.HYPERLINK]: (node: any, children: any) => {
				let ele;
				ele = children[0];
				return (
					<Link gatsby={false} target="_blank" to={node.data.uri}>
						{ele}
					</Link>
				);
			},
			[INLINES.EMBEDDED_ENTRY]: (node: any, children: any) => {
				if (references) {
					let asset: BlogNodeBodyReferenceType;
					for (let i = 0; i < references.length; i++) {
						let ref: BlogNodeBodyReferenceType = references[i];
						if (ref.contentful_id === node.data.target.sys.id) {
							asset = ref;
							if (asset) {
								if (asset.videoId && asset.videoId.length > 0) {
									return (
										<VideoContainer
											css={css`
												@media (max-width: ${theme.breakpoints.lg}) {
													height: 240px;
												} ;
											`}
										>
											<Video
												videoId={asset.videoId}
												imageUrl={
													asset.thumbnail
														? asset.thumbnail.file.url
														: undefined
												}
											/>
										</VideoContainer>
									);
								} else if (asset.quote && asset.quote.quote.length > 0) {
									return (
										<Typography
											variant="div"
											weight="bolder"
											color={theme.colors.primary.secondaryBrand}
											css={css`
												font-size: 20px;
												width: 100%;
												margin-top: 40px;
												margin-bottom: 40px;
												border-left: 4px solid
													${theme.colors.primary.secondaryBrand};
												padding-left: 30px;
												box-sizing: border-box;
												@media (max-width: ${theme.breakpoints.lg}) {
													padding-left: 15px;
													margin-top: 20px;
													margin-bottom: 20px;
												}
											`}
										>
											<Italics>"{asset.quote.quote}"</Italics>
											{asset.author && asset.author.length > 0 && (
												<Typography
													variant="div"
													size="md"
													weight="normal"
													color={theme.colors.primary.secondaryBrand}
													css={css`
														margin-top: 20px;
														@media (max-width: ${theme.breakpoints
																.lg}) {
															margin-top: 10px;
														}
													`}
												>
													{asset.author}
												</Typography>
											)}
										</Typography>
									);
								} else if (asset.callout && asset.callout.callout.length > 0) {
									return (
										<Typography
											variant="div"
											size="md"
											color={theme.colors.primary.secondaryBrand}
											css={css`
												padding: 30px 30px 30px 86px;
												position: relative;
												box-sizing: border-box;
												background-color: ${theme.colors.primary
													.lighterGray};
												@media (max-width: ${theme.breakpoints.lg}) {
													padding: 20px 20px 20px 58px;
												}
											`}
										>
											<img
												src="/images/gear-image.png"
												css={css`
													width: 36px;
													height: auto;
													position: absolute;
													left: 30px;
													top: 30px;
													@media (max-width: ${theme.breakpoints.lg}) {
														left: 20px;
														top: 20px;
														width: 28px;
													}
												`}
											/>
											{asset.callout.callout}
										</Typography>
									);
								} else if (
									asset.file &&
									asset.file.url &&
									asset.file.url.length > 0
								) {
									return (
										<img
											src={asset.file.url}
											css={css`
												display: block;
												max-width: 100%;
												height: auto;
												margin: 0 auto;
											`}
										/>
									);
								} else if (
									asset.label &&
									((asset.downloadableFile &&
										asset.downloadableFile.file &&
										asset.downloadableFile.file.fileName &&
										asset.downloadableFile.file.url) ||
										(asset.linkButtonUrl && asset.linkButtonUrl.length > 0))
								) {
									let localAsset = asset;
									return (
										<div
											css={css`
												display: block;
												width: 100%;
												text-align: center;
												padding-top: 15px;
												padding-bottom: 15px;
											`}
										>
											<div
												css={css`
													display: inline-block;
													width: auto;
													height: auto;
												`}
											>
												<Button
													label={
														localAsset && localAsset.label
															? localAsset.label
															: ""
													}
													type="primary"
													size="md"
													shape="rounded"
													css={css`
														display: inline-block;
													`}
													onClick={() => {
														if (
															localAsset.downloadableFile &&
															localAsset.downloadableFile.file &&
															localAsset.downloadableFile.file
																.fileName &&
															localAsset.downloadableFile.file.url
														) {
															let dlink = document.createElement("a");
															dlink.href =
																localAsset.downloadableFile.file.url;
															dlink.target = "_blank";
															dlink.download =
																localAsset.downloadableFile.file.fileName;
															dlink.dispatchEvent(
																new MouseEvent("click")
															);
														} else {
															let dlink = document.createElement("a");
															dlink.href =
																localAsset.linkButtonUrl as string;
															dlink.target = "_blank";
															dlink.dispatchEvent(
																new MouseEvent("click")
															);
														}
													}}
												/>
											</div>
										</div>
									);
								} else {
									return <></>;
								}
							} else {
								return <></>;
							}
						}
					}
				} else {
					return <></>;
				}
			},
			[BLOCKS.PARAGRAPH]: (node: any, children: any) => (
				<Typography
					css={css`
						white-space: pre-line;
						margin-top: 20px;
						margin-bottom: 20px;
					`}
					variant="div"
					size="md"
					color={theme.colors.primary.secondaryBrand}
				>
					{children}
				</Typography>
			),
			[BLOCKS.HEADING_2]: (node: any, children: any) => (
				<Typography
					css={css`
						font-size: 24px;
						line-height: 28px;
						@media (max-width: ${theme.breakpoints.lg}) {
							font-size: 20px;
							line-height: 24px;
						}
					`}
					weight="bolder"
					variant="h2"
				>
					{children}
				</Typography>
			),
			[BLOCKS.HEADING_3]: (node: any, children: any) => (
				<Typography
					css={css`
						font-size: 20px;
						line-height: 25px;
						@media (max-width: ${theme.breakpoints.lg}) {
							font-size: 16px;
							line-height: 20px;
						}
					`}
					weight="bolder"
					variant="h3"
				>
					{children}
				</Typography>
			),
			[BLOCKS.HEADING_4]: (node: any, children: any) => (
				<Typography
					weight="bolder"
					variant="h4"
					css={css`
						font-size: 16px;
						font-weight: 500;
						font-family: ${theme.typography.fontFamilies.primary};
						line-height: 20px;
						@media (max-width: ${theme.breakpoints.lg}) {
							font-size: 14px;
							line-height: 18px;
						}
					`}
				>
					{children}
				</Typography>
			),
			[BLOCKS.HEADING_5]: (node: any, children: any) => (
				<Typography
					variant="h5"
					weight="bolder"
					css={css`
						font-size: 14px;
						font-weight: 500;
						font-family: ${theme.typography.fontFamilies.primary};
						line-height: 18px;
						@media (max-width: ${theme.breakpoints.lg}) {
							font-size: 12px;
							line-height: 16px;
						}
					`}
				>
					{children}
				</Typography>
			),
			[BLOCKS.EMBEDDED_ASSET]: (node: any, children: any) => {
				if (references) {
					let asset: any;
					references.forEach((ref: any, index: any) => {
						if (
							ref &&
							ref.file &&
							ref.file.url &&
							ref.file.url.includes(node.data.target.sys.id)
						) {
							asset = ref;
						}
					});
					if (asset) {
						return (
							<img
								css={css`
									display: block;
									max-width: 100%;
									margin: 0 auto;
								`}
								src={asset.file.url}
							/>
						);
					} else {
						return <></>;
					}
				} else {
					return <></>;
				}
			},
		},
	};
	return documentToReactComponents(JSON.parse(rawData), options);
};
